import { Asset, AssetListItem, AssetWorkflowUpdateReason } from '@gain/rpc/cms-model'
import { AssetNextUpdateDateType } from '@gain/rpc/cms-model'
import LoadingButton from '@mui/lab/LoadingButton'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Paper from '@mui/material/Paper'
import { styled, useTheme } from '@mui/material/styles'
import { addYears } from 'date-fns/addYears'
import { ForwardedRef, forwardRef } from 'react'

import {
  InputFieldDate,
  InputFieldRadioGroup,
  InputFormProvider,
  useInputFormAPI,
} from '../../../../common/input-fields'
import { getAssetHQ, NextUpdateDateType } from './publish-asset-utils'

interface PublishAssetSnackbarProps {
  asset: Asset | AssetListItem
  onClose: () => void
  onSubmit: (setUpdateReason: () => Promise<void>) => void
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  minWidth: 352,
  borderRadius: theme.shape.borderRadius,
}))

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(),
}))

function PublishAssetSnackbar(
  { asset, onSubmit, onClose }: PublishAssetSnackbarProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const theme = useTheme()

  const inputFormAPI = useInputFormAPI({
    defaultValues: {
      updateReason: AssetWorkflowUpdateReason.NoUpdate,
      nextUpdateDateType: AssetNextUpdateDateType.Automated,
    },
  })

  const handleSubmit = inputFormAPI.form.handleSubmit((formData) => {
    onSubmit(async () => {
      await inputFormAPI.request('cms.updateAssetWorkflowUpdateReason', {
        id: asset.id,
        updateReason: formData.updateReason,
        nextUpdateDateType: formData.nextUpdateDateType,
        nextUpdateDate:
          formData.nextUpdateDateType === AssetNextUpdateDateType.Manual
            ? formData.nextUpdateDate
            : null,
      })
    })
  })

  const watchNextUpdateDateType = inputFormAPI.form.watch('nextUpdateDateType')

  return (
    <ClickAwayListener onClickAway={onClose}>
      <StyledPaper ref={ref}>
        <InputFormProvider form={inputFormAPI.form}>
          <DialogTitle>Publish options</DialogTitle>
          <StyledDialogContent>
            <InputFieldRadioGroup
              label={'Reason for update'}
              name={'updateReason'}
              options={[
                {
                  label: 'New annual report',
                  value: AssetWorkflowUpdateReason.NewAR,
                },
                {
                  label: 'Other/Full update',
                  value: AssetWorkflowUpdateReason.FullUpdate,
                },
                {
                  label: 'Partial news update',
                  value: AssetWorkflowUpdateReason.PartialNewsUpdate,
                },
                {
                  label: 'Full news update',
                  value: AssetWorkflowUpdateReason.FullNewsUpdate,
                },
                {
                  label: 'Partial update',
                  value: AssetWorkflowUpdateReason.PartialUpdate,
                },
                {
                  label: 'False ping',
                  value: AssetWorkflowUpdateReason.FalsePing,
                },
                {
                  label: 'Do not record as update',
                  value: AssetWorkflowUpdateReason.NoUpdate,
                },
              ]}
              required
            />

            <InputFieldRadioGroup
              label={'Next update date'}
              name={'nextUpdateDateType'}
              options={[
                {
                  label: 'Use automated date',
                  value: AssetNextUpdateDateType.Automated,
                },
                {
                  label: 'Keep existing date',
                  value: AssetNextUpdateDateType.Existing,
                },
                {
                  label: 'Manually set the date',
                  value: AssetNextUpdateDateType.Manual,
                },
              ]}
              required
            />

            {watchNextUpdateDateType === NextUpdateDateType.Manual && (
              <InputFieldDate
                label={'On which date?'}
                maxDate={
                  getAssetHQ(asset) === 'US' ? addYears(new Date(), 1.5) : addYears(new Date(), 1)
                }
                name={'nextUpdateDate'}
                slotProps={{
                  popper: {
                    slotProps: {
                      root: {
                        style: { zIndex: theme.zIndex.snackbar },
                      },
                    },
                  },
                }}
                disablePast
              />
            )}
          </StyledDialogContent>
          <DialogActions>
            <LoadingButton
              loading={inputFormAPI.busy}
              onClick={handleSubmit}
              variant={'contained'}>
              Continue
            </LoadingButton>
          </DialogActions>
        </InputFormProvider>
      </StyledPaper>
    </ClickAwayListener>
  )
}

export default forwardRef(PublishAssetSnackbar)
